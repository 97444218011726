import { useRef, useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { AppImages } from "../../general/constants/AppResources";
import "./styles.scss";
import axios from "axios";
import data from "../../general/Global";
import { useNavigate } from "react-router-dom";

function Dashboard(props) {
  const [image, setImage] = useState();
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const handleClick = () => {
    inputRef.current.click();
  };
  const handleChange = async (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      console.log(files);
      data.files = files[0];
      navigate("/process");
    }
  };

  return (
    <div className="layout">
      <Header />
      <div className="container mt-5">
        <div className="row ">
          <div className="col-md-6  d-flex flex-column justify-content-center align-items-center">
            <span
              className="font-weight-boldest  line-height-md text-uppercase "
              style={{ color: "#273B98", fontSize: "45px" }}
            >
              Hệ thống phân tích dữ liệu từ hình ảnh
            </span>
            <span
              className="font-weight-bold mb-6 text-justify"
              style={{ color: "#5E6278", fontSize: "17px" }}
            >
              Cung cấp tùy chọn thu nhận hình ảnh trực tiếp từ ứng dụng hoặc
              chạy các phân tích tự động lặp lại trên dữ liệu đã thu thập
            </span>
            <div className="d-flex flex-row ">
              {/* <div className="btn" style={{height: "44px", borderColor: "#3E62E0" }} > */}

              <input
                type="file"
                accept=".png, .jpg, .jpeg, .pdf, .PDF"
                id="files"
                onChange={handleChange}
                ref={inputRef}
                style={{ display: "none" }}
              />
              <div className="imageInput" onClick={handleClick}></div>
              {/* </div> */}
              {/* <div
                className="btn position-relative d-flex align-items-baseline justify-content-center"
                style={{
                  height: "44px",
                  backgroundColor: "#3ACE5A",
                  borderColor: "#2AB448",
                  right : "105px"
                }}
              >
                <i className="fas fa-play ml-2 mr-3" style={{ color: "white", width:"14px" , height:"15px" }}></i>
                <span className="text-uppercase font-weight-bolder mr-2" style={{ color: "white" ,fontSize:"16px"  }}>
                  Xử lý
                </span>
              </div> */}
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center p-0">
            <img
              className="w-75"
              style={{ objectFit: "cover" }}
              src={AppImages.imgBackgroundDashboard}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Dashboard;
