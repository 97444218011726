import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

BaseTextField.propTypes = {
  name: PropTypes.string.isRequired,
  fieldProps: PropTypes.object,
  fieldMeta: PropTypes.object,
  fieldHelpers: PropTypes.object,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  text: PropTypes.string,
  className: PropTypes.string,
  spellCheck: PropTypes.bool,
};

BaseTextField.defaultProps = {
  type: "text",
  label: "",
  placeholder: "",
  disabled: false,
  text: "",
  spellCheck: false,

  fieldProps: {},
  fieldHelpers: {},
  fieldMeta: {},

  className: "form-group",
};

/**
 * @param className
 * @param name id of input tag
 * @param type type of input tag
 * @param label label of input tag
 * @param placeholder input place holder
 * @param text muted-text below input field
 * @param fieldProps for form helper
 * @param fieldHelpers for form helper
 * @param fieldMeta for form helper
 */
function BaseTextField(props) {
  // MARK: --- Params ---
  const {
    name,
    fieldProps,
    fieldHelpers,
    fieldMeta,
    type,
    label,
    placeholder,
    disabled,
    text,
    className,
    spellCheck,
  } = props;
  const { error, touched } = fieldMeta;
  const isError = error && touched;

  return (
    <div className="d-flex justifu-content-center align-items-center mr-2">
      {/* {
                    label && (<label htmlFor={name}>{label}</label>)
                } */}
      <input
        className={`form-control Regular_13 ${isError && "is-invalid"}`}
        // style={{height:"70px !important"}}
        id={name}
        disabled={disabled}
        type={type}
        name={name}
        placeholder={placeholder}
        spellCheck={spellCheck}
        {...fieldProps}
      />
      {text.length > 0 && <span className="form-text text-muted">{text}</span>}
      {isError && (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">{error}</div>
        </div>
      )}
    </div>
  );
}

export default BaseTextField;
