import "./styles.scss";

function Footer(props) {
  return (
    <div className="d-flex justify-content-start align-items-center footer px-5 ">
      <span className="text-uppercase font-weight-bolder" style={{ color: "#5A5A89", fontSize:"11px",  }}>
        copyright by MDC data analytics
      </span>
    </div>
  );
}
export default Footer;
