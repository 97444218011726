import { useState } from "react";
import { AppIcons } from "../../general/constants/AppResources";
import "./styles.scss";
function Header(props) {
  return (
    <div className="navBar d-flex justify-content-around align-items-center bg-white ">
      <div className="d-flex aligns-items-center">
        <img src={AppIcons.icLogo} className="logo" />
        <span
          className="font-weight-bolder font-size-h5 ml-2"
          style={{ color: "#273B98" }}
        >
          MDC Analytic
        </span>
      </div>
      <div className="position-relative">
        <input
          className="form-control bg-light"
          style={{ height: "32px", width: "400px" }}
        />
        <span className="iconSearch">
          <img src={AppIcons.icSearch} />
        </span>
      </div>
      <div className="d-flex justify-content-between align-items-center h-100">
        <p className="navText">Statistics</p>
        <p className="navText mx-5">Overview</p>
        <p className="navText">Analytics</p>
      </div>
      <div className="d-flex flex-row align-items-center">
        <img src={AppIcons.icAccount} className="mr-6" />
        <img src={AppIcons.icSetting} />
      </div>
    </div>
  );
}
export default Header;
