import { useEffect, useState } from "react";
import BaseTextField from "../../../../components/BaseTextField";
import "./styles.scss";

function ResultCell(props) {
  let { title, image, textResult, className } = props;
  const isNull = (object) => {
    if (object !== undefined && object !== null) return false;
    else return true;
  };

  return (
    <div
      className={`row mt-3 d-flex flex-column align-items-start ml-1 rounded ${className}`}
    >
      <div className="font-weight-bold textResultLabel" style={{ width: 300 }}>
        <span className="font-weight-bold textResultLabel">{title}</span>
      </div>
      {image
        ? image.map(function (item) {
            return (
              <div className="d-flex flex-grow-1 mt-2 mb-2">
                <div className="d-flex flex-column flex-grow-1">
                  <img
                    src={item ? "data:image/png;base64," + item : null}
                    style={{ height: "40px", objectFit: "contain" }}
                  />
                </div>
                {/* <div
                  className="d-flex justify-content-center align-items-center ml-2"
                  style={{ width: 150 }}
                >
                  <BaseTextField />
                </div> */}
              </div>
            );
          })
        : null}
    </div>
  );
}

export default ResultCell;
