import { useEffect, useRef, useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { AppImages } from "../../general/constants/AppResources";
import axios from "axios";
import "./styles.scss";
import data from "../../general/Global";
import ResultCell from "./components/ResultCell";
import { Circles } from "react-loader-spinner";

function Process(props) {
  const [imagesUrl, setImagesUrl] = useState();
  const [originalImagesUrl, setOrginalImagesUrl] = useState();
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  const handleClick = () => {
    inputRef.current.click();
  };
  const isNull = (object) => {
    if (object !== undefined && object !== null) return false;
    else return true;
  };

  async function fetchData() {
    try {
      setLoading(true);
      let formdata = new FormData();
      formdata.append("img", data.files);

      let bodyContent = formdata;

      let reqOptions = {
        url: "https://ai.mdcgate.com/analytics/KS/detect-data",
        method: "POST",
        data: bodyContent,
        timeout: 1000 * 15,
      };

      let response = await axios.request(reqOptions);
      console.log(response.data);
      setOrginalImagesUrl(response?.data?.result?.img);
      setImagesUrl(response?.data?.result?.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  const handleChange = async (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      console.log(files);
      data.files = files[0];
      fetchData();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [bodyHeight, setBodyHeight] = useState(window.innerHeight - 250);

  function handleResize() {
    console.log(window.innerHeight - 150);
    setBodyHeight(window.innerHeight - 150);
  }

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="layout">
      <Header />
      <div className="container-fluid mt-10 rounded">
        <div className="row rounded">
          <div
            className="col-md-6 resultColumn rounded"
            // style={{ maxHeight: bodyHeight }}
          >
            <div className="col-12 d-flex flex-column justify-content-center bg-white rounded pb-10 pt-2">
              <span
                className="my-4 ml-5"
                style={{
                  color: "#383874",
                  fontWeight: "600",
                  fontSize: "22px",
                }}
              >
                Kết quả phân tích
              </span>
              <ResultCell title="Số" image={imagesUrl ? imagesUrl.So : null} />
              <ResultCell
                title="Ngày đăng kí"
                image={imagesUrl ? imagesUrl.NgayDangKi : null}
              />
              <ResultCell
                title="Họ và tên"
                image={imagesUrl ? imagesUrl.Ten : null}
              />
              <ResultCell
                title="Ngày sinh"
                image={imagesUrl ? imagesUrl.NgaySinh : null}
              />
              <ResultCell
                title="Giới tính"
                image={imagesUrl ? imagesUrl.GioiTinh : null}
              />
              <ResultCell
                title="Dân tộc"
                image={imagesUrl ? imagesUrl.DanToc : null}
              />
              <ResultCell
                title="Quốc tịch"
                image={imagesUrl ? imagesUrl.QuocTich : null}
              />
              <ResultCell
                title="Nơi sinh"
                image={imagesUrl ? imagesUrl.NoiSinh : null}
              />
              <ResultCell
                title="Quê quán"
                image={imagesUrl ? imagesUrl.QueQuan : null}
              />
              <ResultCell
                title="Mã định danh"
                image={imagesUrl ? imagesUrl.MaDinhDanh : null}
              />
              <ResultCell
                title="Tên mẹ"
                className="mt-10"
                image={imagesUrl ? imagesUrl.TenMe : null}
              />
              <ResultCell
                title="Năm sinh mẹ"
                image={imagesUrl ? imagesUrl.NamSinhMe : null}
              />
              <ResultCell
                title="Dân tộc mẹ"
                image={imagesUrl ? imagesUrl.DanTocMe : null}
              />
              <ResultCell
                title="Quốc tịch mẹ"
                image={imagesUrl ? imagesUrl.QuocTichMe : null}
              />
              <ResultCell
                title="Nơi cư trú Mẹ"
                image={imagesUrl ? imagesUrl.NoiCuTruMe : null}
              />
              <ResultCell
                title="Tên cha"
                className="mt-10"
                image={imagesUrl ? imagesUrl.TenBo : null}
              />
              <ResultCell
                title="Năm sinh cha"
                image={imagesUrl ? imagesUrl.NamSinhBo : null}
              />
              <ResultCell
                title="Dân tộc cha"
                image={imagesUrl ? imagesUrl.DanTocBo : null}
              />
              <ResultCell
                title="Quốc tịch cha"
                image={imagesUrl ? imagesUrl.QuocTichBo : null}
              />
              <ResultCell
                title="Nơi cư trú"
                image={imagesUrl ? imagesUrl.NoiCuTruBo : null}
              />
              <ResultCell
                title="Tên người khai sinh"
                image={imagesUrl ? imagesUrl.TenNguoiKS : null}
              />
              <ResultCell
                title="Giấy tờ tùy thân"
                image={imagesUrl ? imagesUrl.GiayToTuyThan : null}
              />
              <ResultCell
                title="Cán bộ khai sinh"
                image={imagesUrl ? imagesUrl.CanBoKS : null}
              />
            </div>
          </div>

          <div
            className="col-6 d-flex  flex-column"
            style={{ height: bodyHeight }}
          >
            <div className="col-12 py-6  d-flex justify-content-center align-items-center bg-white rounded flex-column ">
              {isNull(originalImagesUrl) ? (
                <img
                  className="h-100 e"
                  style={{
                    objectFit: "contain",
                    // maxHeight: bodyHeight - 150,
                    maxWidth: "100%",
                  }}
                  src={AppImages.imgDefault}
                />
              ) : (
                <img
                  className=""
                  style={{
                    objectFit: "contain",
                    // maxHeight: bodyHeight,

                    maxWidth: "100%",
                  }}
                  src={
                    originalImagesUrl
                      ? "data:image/jpg;base64," + originalImagesUrl
                      : null
                  }
                />
              )}

              {/* <img
                className=""
                style={{ objectFit: "contain", maxHeight: bodyHeight - 150 }}
                src={
                  originalImagesUrl
                    ? "data:image/jpg;base64," + originalImagesUrl
                    : null
                }
              /> */}
              {loading === false ? null : (
                <div className="mt-2 mb-2">
                  <Circles
                    height="40"
                    width="40"
                    color="#4fa94d"
                    ariaLabel="circles-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    style={{}}
                  />
                </div>
              )}

              <input
                type="file"
                accept=".png, .jpg, .jpeg, .pdf, .PDF"
                className="imageInput mt-4"
                id="files"
                onChange={handleChange}
                ref={inputRef}
                style={{ display: "none" }}
              />
              <div className="imageInput mt-4" onClick={handleClick}></div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Process;
