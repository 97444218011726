import icLogo from "../../assets/icons/ic_logo.svg";
import icAccount from "../../assets/icons/ic_account.svg";
import icSetting from "../../assets/icons/ic_setting.svg";
import icSearch from "../../assets/icons/ic_search.svg";


// Icons
export const AppIcons = {
  icLogo,
  icAccount,
  icSetting,
  icSearch
};

// Images
export const AppImages = {
   imgBackgroundDashboard : require("../../assets/images/img_background_dashboard.png"),
   imgDefault: require("../../assets/images/img_default.jpg"),
  

};
